<template>
	<div class="modal fade" ref="target" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<p>{{ message }}</p>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" @click="cancel">{{ cancelButton }}</button>
					<button class="btn btn-primary" @click="confirm">{{ okButton }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Modal} from 'bootstrap';

export default {
	name: 'ConfirmDialogue',

	data: () => ({
		// Parameters that change depending on the type of dialogue
		message: undefined,
		okButton: undefined,
		cancelButton: undefined,

		// Private variables
		resolvePromise: undefined,
		rejectPromise: undefined,

		modal: null,
	}),

	methods: {
		show(opts = {}) {
			this.message = opts.message;
			this.okButton = opts.okButton;
			this.cancelButton = opts.cancelButton;

			// Once we set our config, we tell the popup modal to open
			this.modal.show();

			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},

		confirm() {
			this.modal.hide();
			this.resolvePromise(true);
		},

		cancel() {
			this.modal.hide();
			this.resolvePromise(false);
		},
	},

	mounted() {
		this.modal = new Modal(this.$refs.target);
	},
};
</script>
