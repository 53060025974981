<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<div class="d-flex justify-content-between mb-3">
					<h1>Edit</h1>
					<div v-if="editUser"><span v-if="editUser.hidden == 1" class="badge bg-dark fs-6">deactivated</span><span v-if="authUserId == editUser.id" class="badge bg-primary fs-6">It's me</span> <span class="badge bg-primary fs-6">{{ editUser.username }}</span></div>
				</div>
				<div v-if="editUser" class="edit-form">
					<Form @submit="updateUser" :validation-schema="schema">
						<div class="form-floating mb-3">
							<Field name="first_name" type="text" id="first_name" class="form-control" v-model="editUser.first_name" />
							<label for="first_name">First Name</label>
							<ErrorMessage name="first_name" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field name="last_name" type="text" id="last_name" class="form-control" v-model="editUser.last_name" />
							<label for="last_name">Last Name</label>
							<ErrorMessage name="last_name" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field name="email" type="email" id="email" class="form-control" v-model="editUser.email" />
							<label for="email">Email</label>
							<ErrorMessage name="email" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field name="phone" type="tel" id="phone" class="form-control" v-model="editUser.phone" />
							<label for="phone">Phone</label>
							<ErrorMessage name="phone" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field name="mobile" type="tel" id="mobile" class="form-control" v-model="editUser.mobile" />
							<label for="mobile">Mobile</label>
							<ErrorMessage name="mobile" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field name="web" type="url" id="web" class="form-control" v-model="editUser.web" />
							<label for="web">Web</label>
							<ErrorMessage name="web" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="row">
							<div class="col mb-3">
								<label for="hexcolor">Hexcolor</label>
								<Field name="hexcolor" type="color" id="hexcolor" class="form-control form-control-color" v-model="editUser.hexcolor" />
								<ErrorMessage name="hexcolor" class="error-feedback d-block alert alert-warning" />
							</div>

							<div class="col mb-3">
								<label for="icon">Icon</label>
								<Field v-slot="{value}" name="icon" as="select" v-model="editUser.icon" class="form-select" aria-label="Icon">
									<option value="" disabled>Select a icon</option>
									<option v-for="(icon, index) in icons" :key="icon" :value="index" :selected="value && value.includes(index)">{{ icon }}</option>
								</Field>
							</div>
						</div>

						<div class="mb-3">
							<label for="message">Message</label>
							<Field name="message" as="textarea" rows="10" id="message" class="form-control" v-model="editUser.message" />
							<ErrorMessage name="message" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field v-slot="{value}" name="role" as="select" v-model="editUser.role" class="form-select" aria-label="Role">
								<option value="" disabled>Select role</option>
								<option v-for="role in roles" :key="role.id" :value="role.id" :selected="value && value.includes(role.id)">{{ role.title }}</option>
							</Field>
							<label for="icon">Role</label>
						</div>

						<div class="form-floating mb-3">
							<Field name="newpassword" type="password" id="newpassword" class="form-control" v-model="editUser.newpassword" />
							<label for="hexcolor">New Password</label>
						</div>

						<div class="mb-3 d-flex">
							<button class="btn btn-primary me-auto" :disabled="updateProgess">
								<span v-show="updateProgess" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
								<span>Update</span>
							</button>
							<a class="btn btn-primary me-2" @click="confirmDelete">Delete</a>
							<router-link class="btn btn-primary" to="/users">Back</router-link>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>

	<confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import ConfirmDialogue from '../components/_ConfirmDialogue.vue';
import UserService from '../services/user.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppUserEdit',
	components: {
		Form,
		Field,
		ErrorMessage,
		ConfirmDialogue,
	},
	data() {
		const schema = yup.object().shape({
			first_name: yup.string().min(3, 'Please enter more than 3 character').required('First name is required!'),
			last_name: yup.string().min(3, 'Please enter more than 3 character').required('Last name is required!'),
		});

		return {
			editUser: null,
			authUserId: null,
			updateProgess: false,
			schema,
			icons: null,
			roles: null,
		};
	},
	methods: {
		async confirmDelete() {
			const ok = await this.$refs.confirmDialogue.show({
				message: 'Are you sure you want to delete this user? It cannot be undone.',
				okButton: 'Yes',
				cancelButton: 'No',
			});

			if (ok) {
				this.deleteUser();
			}
		},
		getUser(id) {
			UserService.get(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.editUser = response.data.user;
					} else {
						this.editUser = null;
						this.$toast.warning(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		updateUser() {
			this.updateProgess = true;
			UserService.update(this.editUser.id, this.editUser)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$toast.success(response.data.success.message);
					} else {
						this.$toast.info(response.data.error.message);
					}
					this.editUser.newpassword = '';
					this.updateProgess = false;
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		deleteUser() {
			UserService.delete(this.editUser.id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$router.push('/users');
					} else {
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.updateProgess = false;
				console.log(e);
			}
		},
	},
	created() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user && user.accessToken) {
			this.authUserId = user.id;
		}

		this.roles = this.$store.state.auth.roles;
		this.icons = this.$store.state.icons.options;
		this.getUser(this.$route.params.id);
	},
	mounted() {},
};
</script>
